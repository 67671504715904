import styled from 'styled-components'

const MainContent = styled.div`
  grid-area: main;
`

const Sidebar = styled.div`
  grid-area: sidebar;
  margin: 0 20px 20px 20px;
  background: white;
  border-radius: 3px;
  box-shadow: 0 2px 2px 2px #00000008;
  position: relative;
  @media (max-width: 900px) {
    display: none;
  }
`

const StickySidebarContent = styled.div`
  position: sticky;
  top: 130px;
  padding: 0 15px 0 15px;
`

const PageLayout = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-areas: 'main sidebar';
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-template-areas: 'main';
  }
`

export {MainContent, Sidebar, PageLayout, StickySidebarContent}
