import React from 'react'
import {Link, graphql} from 'gatsby'
import styled from 'styled-components'
import {stripLastParagraph, decodeHtmlEncodedString} from '../../../utils'

const LeftPad = 'padding-left: 40px;'
const RightPad = 'padding-right: 40px;'

const ImageContainer = styled.div`
  background: red;
  grid-area: image;
  background: ${({background, theme}) =>
    background && background.sourceUrl
      ? `url('${background.sourceUrl}')`
      : theme.grey1};
  background-size: cover;
`

const Title = styled.div`
  grid-area: title;
  ${LeftPad}
  align-self: center;

  @media (max-width: 768px) {
    padding: 20px 0;
    ${LeftPad}
  }
`

const Exerpt = styled.div`
  grid-area: exerpt;
  ${LeftPad}
  align-self: center;
  max-height: 100px;
  overflow: hidden;

  @media (max-width: 768px) {
    margin-bottom: 20px;
    ${LeftPad}
    ${RightPad}
  }
`

const ActionButton = styled(Link)`
  grid-area: action;
  margin-left: 40px;
  align-self: center;
  background: ${({theme}) => theme.grey1};
  color: white;
  border-radius: 200px;
  padding: 6px 20px;
  border: 3px solid white;
  justify-self: start;
  cursor: pointer;
  transition: 0.3s all;
  &:hover {
    background: ${({theme}) => theme.redHover};
    color: white;
  }
`

const Meta = styled.div`
  grid-area: meta;
  align-self: center;
  justify-self: right;
  display: flex;
  @media (max-width: 768px) {
    ${RightPad}
  }
  @media (max-width: 450px) {
    flex-direction: row-reverse;
    /* ${LeftPad} */
    justify-self: flex-start;
    text-align: left;
    padding-left: 20px;
    padding-bottom: 20px;
    & p {
      text-align: left;
    }
  }
  & img {
    border-radius: 500px;
    width: 50px;
    height: 50px;
    margin-left: 20px;
  }
  & p {
    margin: 0;
    text-align: right;
    @media (max-width: 450px) {
      text-align: left;
      padding-left: 10px;
    }
  }
  & p:first-child {
    font-weight: 900;
  }
`

const Container = styled(Link)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'image title title'
    'image exerpt exerpt'
    'image action meta';
  border-radius: 3px;
  box-shadow: 0 0 2px 2px #00000008;
  overflow: hidden;
  @media (max-width: 768px) {
    height: 500px;
    grid-template-rows: 1.5fr auto auto auto;
    grid-template-areas:
      'image image image'
      'title title title'
      'exerpt exerpt exerpt'
      'action meta meta';
    padding-right: 0;
    padding-bottom: 20px;
  }
  @media (max-width: 450px) {
    height: 500px;
    grid-template-rows: 1.5fr auto auto auto auto;
    grid-template-areas:
      'image image image'
      'title title title'
      'meta meta meta'
      'exerpt exerpt exerpt'
      'action action action';
    padding-right: 0;
    padding-bottom: 20px;
  }
  margin-bottom: 20px;
  ${RightPad}
  height: 300px;
  background: ${props => props.theme.white};
  color: ${props => props.theme.grey1};
  p,
  h3,
  h4,
  h5 {
    color: ${props => props.theme.grey1};
  }
  h3 {
    text-transform: capitalize;
  }
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: translateX(6px);
    @media (max-width: 768px) {
      transform: none;
    }
    & a {
      background: ${({theme}) => theme.redHover};
      color: white;
    }
  }
`

// A post entry - a single item in the list when on the blog landing page
const PostEntry = ({post}) => {
  return (
    <Container to={`/blog/${post.uri}/`}>
      <ImageContainer background={post.featuredImage} />
      <Title>
        <Link to={`/blog/${post.uri}/`}>
          <h3>{decodeHtmlEncodedString(post.title)}</h3>
        </Link>
        {/* <h5>sub</h5> */}
      </Title>
      <Exerpt
        dangerouslySetInnerHTML={{
          __html: stripLastParagraph(post.content),
        }}
      />
      <ActionButton to={`/blog/${post.uri}/`}>Read More</ActionButton>
      <Meta>
        <div>
          <p>{decodeHtmlEncodedString(post.author.name)}</p>
          <p>{new Date(post.date).toLocaleDateString()}</p>
        </div>
        <img src={post.author.avatar.url} alt="avatar" />
      </Meta>
    </Container>
  )
}

export default PostEntry

export const query = graphql`
  fragment PostEntryFragment on WPGraphQL_Post {
    id
    title
    uri
    slug
    date
    content: excerpt
    author {
      name
      slug
      avatar(size: 100) {
        url
      }
    }
    featuredImage {
      sourceUrl
    }
    seo {
      title
      metaDesc
      focuskw
      metaKeywords
      metaRobotsNoindex
      metaRobotsNofollow
      opengraphTitle
      opengraphDescription
      opengraphImage
      twitterTitle
      twitterDescription
      twitterImage
    }
  }
`
