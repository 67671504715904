import React from 'react'
import {StaticQuery, graphql, Link} from 'gatsby'
import styled from 'styled-components'
import {createLocalLink, decodeHtmlEncodedString} from '../../../utils'

const Container = styled.div`
  h2 {
    text-align: left;
    text-transform: capitalize;
    margin-bottom: 10px !important;
  }
`

const StyledUl = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 -15px;
  & li {
    transition: 0.2s all ease-in-out;
    border-radius: 3px;
    &:hover {
      padding-left: 6px;
      background: ${({theme}) => theme.grey4};
    }
    &:first-child {
      border-top: 1px solid #eee;
    }
  }
`

const StyledLink = styled(Link)`
  display: block;
  color: ${({theme}) => theme.grey2};
  line-height: 20px;
  padding: 15px;
  border-bottom: 1px solid #eee;
  &:hover,
  &[aria-current='page'] {
    color: ${({theme}) => theme.redHover};
  }
`

const QUERY = graphql`
  {
    wpgraphql {
      posts(first: 5) {
        nodes {
          id
          title
          link
        }
      }
    }
  }
`
// The basic widget on the right listing the recent posts
const RecentPostsWidget = () => (
  <StaticQuery
    query={QUERY}
    render={data => {
      return (
        <Container>
          <h2>Recent Posts</h2>
          <StyledUl>
            {data.wpgraphql.posts.nodes.map(post => {
              return (
                <li key={post.id}>
                  <StyledLink to={'/blog/' + createLocalLink(post.link) + '/'}>
                    {decodeHtmlEncodedString(post.title)}
                  </StyledLink>
                </li>
              )
            })}
          </StyledUl>
        </Container>
      )
    }}
  />
)

export default RecentPostsWidget
