import React, {Component} from 'react'
import {Link, graphql} from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import CategoriesWidget from '../components/Blog/CategoriesWidget'
import RecentPostsWidget from '../components/Blog/RecentPostsWidget'
import PostEntry from '../components/Blog/PostEntry'
import PageHeader from '../components/Layout/PageHeader'
import {Inner} from '../components/styles'
import {
  PageLayout,
  MainContent,
  Sidebar,
  StickySidebarContent,
} from '../components/Blog/Layout'

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & a {
    border: 2px solid ${({theme}) => theme.grey1};
    color: ${({theme}) => theme.grey1};
    padding: 10px 20px;
    background: white;
    display: block;
    border-radius: 300px;
    transition: 0.2s all ease-in-out;
    margin: 5px;
    &:hover {
      background: ${({theme}) => theme.redHover};
      border: 2px solid ${({theme}) => theme.redHover};
      color: ${({theme}) => theme.white};
    }
    &.next:hover {
      transform: translateX(6px);
    }
    &.prev:hover {
      transform: translateX(-6px);
    }
  }
`

// The main blog page listing all the posts
class IndexPage extends Component {
  renderPreviousLink = () => {
    const {
      pageContext: {pageNumber},
    } = this.props

    let previousLink = null

    if (!pageNumber) {
      return null
    }

    if (1 === pageNumber) {
      previousLink = `/blog/`
    } else if (1 < pageNumber) {
      previousLink = `/blog/page-${pageNumber - 1}/`
    }
    return (
      <Link className="prev" to={previousLink}>
        &larr; Previous
      </Link>
    )
  }

  renderNextLink = () => {
    const {
      pageContext: {hasNextPage, pageNumber},
    } = this.props

    if (hasNextPage) {
      return (
        <Link className="next" to={`/blog/page-${pageNumber + 1}/`}>
          Next &rarr;
        </Link>
      )
    }
    return null
  }

  render() {
    const {
      data,
      location,
      pageContext: {pageNumber},
      seo,
    } = this.props
    const blogPageNumber = pageNumber ? ` Page ${pageNumber}` : ``
    return (
      <Layout pageNumber={pageNumber} location={location} background="#fafafa">
        <SEO location={location} title={`Blog${blogPageNumber}`} seo={seo} />
        <PageHeader location={location}>Blog</PageHeader>
        <Inner>
          <PageLayout>
            <MainContent>
              {data &&
                data.wpgraphql &&
                data.wpgraphql.posts.nodes.map(post => (
                  <PostEntry post={post} key={post.id} />
                ))}
            </MainContent>
            <Sidebar>
              <StickySidebarContent>
                <CategoriesWidget />
                <RecentPostsWidget />
              </StickySidebarContent>
            </Sidebar>
          </PageLayout>
          <PaginationContainer>
            {this.renderPreviousLink()}
            {this.renderNextLink()}
          </PaginationContainer>
        </Inner>
      </Layout>
    )
  }
}

export default IndexPage

export const query = graphql`
  query GET_POSTS($ids: [ID]) {
    wpgraphql {
      posts(where: {in: $ids}) {
        nodes {
          ...PostEntryFragment
        }
      }
    }
  }
`
