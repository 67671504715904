import React from 'react'
import {StaticQuery, graphql, Link} from 'gatsby'
import styled from 'styled-components'

const Container = styled.div`
  h2 {
    text-align: left;
    text-transform: capitalize;
    margin-bottom: 10px !important;
  }
`

const CategoryLink = styled(Link)`
  border-radius: 200px;
  border: 2px solid ${({theme}) => theme.grey1};
  color: ${({theme}) => theme.grey1};
  background: white;
  padding: 2px 10px;
  margin: 2px;
  transition: 0.2s all;

  &:hover {
    border: 2px solid ${({theme}) => theme.redHover};
    background: ${({theme}) => theme.redHover};
    color: white;
  }
`

const CatContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const QUERY = graphql`
  {
    wpgraphql {
      categories {
        nodes {
          name
          slug
          link
          id
        }
      }
    }
  }
`
// Basic widget listing the categories posts can fall under
const CategoriesWidget = () => (
  <StaticQuery
    query={QUERY}
    render={data => {
      return (
        <Container>
          <h2>Categories</h2>
          <CatContainer>
            {data.wpgraphql.categories.nodes.map(category => {
              return (
                <CategoryLink
                  key={category.id}
                  to={`/category/${category.slug}/`}
                >
                  {category.name}
                </CategoryLink>
              )
            })}
          </CatContainer>
        </Container>
      )
    }}
  />
)

export default CategoriesWidget
